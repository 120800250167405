@import 'helpers'

$meeting-agenda-item--author-width: 300px

.op-meeting-agenda-item
  display: grid
  grid-template-columns: 20px auto 1fr minmax(auto, $meeting-agenda-item--author-width) 40px
  grid-template-areas: "drag-handle content duration author actions" ". notes notes notes notes"

  &--drag-handle,
  &--duration,
  &--content,
  &--author
    align-self: center

  &--actions
    justify-self: end

  &--author
    max-width: $meeting-agenda-item--author-width
    justify-self: end

  &--duration
    white-space: nowrap

  &--content
    @include text-shortener

  @media screen and (max-width: $breakpoint-lg)
    grid-template-columns: 20px auto 1fr 50px
    grid-template-areas: "drag-handle content content actions" ". author author duration" ". notes notes notes"

    &--duration
      justify-self: end
