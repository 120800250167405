.op-storage-list--header,
.op-storage-list--row
  display: grid
  grid-template-columns: 1fr 180px 160px 240px
  grid-template-areas: "name user provider time" "host user provider time"

@media screen and (max-width: $breakpoint-md)
  .op-storage-list--row
    grid-template-columns: 1fr
    grid-template-areas: "name" "host" "user" "time"
    grid-row-gap: 5px

  .op-storage-list--header
    grid-template-columns: 1fr
    grid-template-areas: "name"
